import React from 'react';
import {
  Redirect,
  // useHistory,
  useLocation,
} from 'react-router-dom';
export function LoginPage({ user = {} }) {
  // const history = useHistory();
  const location = useLocation();
  if (user && user.uid) {
    return <Redirect to="/" />;
  }
  const { from } = location.state || { from: { pathname: '/' } };
  const login = () => {
    window.location.replace(
      process.env.NODE_ENV === 'production'
        ? `https://auth.pinchos.se?redirectTo=${window.location.origin}${from.pathname}`
        : `http://localhost:3000?redirectTo=${window.location.origin}${from.pathname}`,
    );
  };
  return (
    <div>
      <p>You must log in to view the page at {from.pathname}</p>
      <button type="button" onClick={login}>
        Log in
      </button>
    </div>
  );
}

import React, { useCallback } from 'react';
import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom';

import { useDispatch } from 'react-redux';
import utils from './utils/init';

import { LoginPage } from './pages/LoginPage';
import { ProfilePage } from './pages/ProfilePage';
import { PrivateRoute } from './components/PrivateRoute';
import { setUser } from './reducers/userReducer';
import { useCheckUser } from './hooks/useCheckUser';

// This example has 3 pages: a public page, a protected
// page, and a login screen. In order to see the protected
// page, you must first login. Pretty standard stuff.
//
// First, visit the public page. Then, visit the protected
// page. You're not yet logged in, so you are redirected
// to the login page. After you login, you are redirected
// back to the protected page.
//
// Notice the URL change each time. If you click the back
// button at this point, would you expect to go back to the
// login page? No! You're already logged in. Try it out,
// and you'll see you go back to the page you visited
// just *before* logging in, the public page.

export default function App() {
  const [loading, , /* setLoading */ error, setError] = useCheckUser();
  const dispatch = useDispatch();
  const onSignOut = useCallback(async () => {
    try {
      await utils.signOut();
      dispatch(setUser(null));
    } catch (err) {
      setError(err);
    }
  }, [dispatch, setError]);

  if (loading) {
    return null;
  }

  if (error) {
    return (
      <div>
        Something went wrong,{' '}
        <button type="button" onClick={() => window.location.reload()}>
          reload
        </button>{' '}
        and try again
        {error.stack && (
          <pre
            style={{ padding: '2em', background: 'slategray', color: 'white' }}
          >
            {error.stack}
          </pre>
        )}
      </div>
    );
  }

  return (
    <Router>
      <div>
        <ul>
          <li>
            <Link to="/profile">Profile Page</Link>
          </li>
          <li>
            <Link to="/public">Public Page</Link>
          </li>
          <li>
            <Link to="/protected">Protected Page</Link>
          </li>
        </ul>

        <Switch>
          <PrivateRoute path="/profile">
            <ProfilePage onSignOut={onSignOut} />
          </PrivateRoute>
          <Route path="/public">
            <PublicPage />
          </Route>
          <Route path="/login">
            <LoginPage />
          </Route>
          <PrivateRoute path="/protected">
            <ProtectedPage />
          </PrivateRoute>
        </Switch>
      </div>
    </Router>
  );
}

function PublicPage({ user = {} }) {
  return <h3>Public {user && user.phoneNumber}</h3>;
}

function ProtectedPage({ user = {} }) {
  return <h3>Protected {user && user.phoneNumber}</h3>;
}

import { configureStore, getDefaultMiddleware } from 'redux-starter-kit';
import logger from 'redux-logger';

import userReducer from './reducers/userReducer';

/**
 * This redux setup is made with `redux-starter-kit`. Read more about the kit here https://redux-starter-kit.js.org/usage/usage-guide.
 * TL;DR:
 * Reduce boilerplate for setting up a store, creating reducers and actions. Get default middlewares such as devTools.
 * See userReducer for minimal setup example, pretty much.
 */

const reducer = {
  user: userReducer,
};

const middleware = [
  ...getDefaultMiddleware(),
  ...(process.env.NODE_ENV !== 'production' ? [logger] : []),
];

// const preloadedState = {
//   user: {}, // not needed since initial state is defined in the reducer
// };

// Read about configureStore: https://redux-starter-kit.js.org/api/configurestore
const store = configureStore({
  reducer,
  middleware,
  devTools: process.env.NODE_ENV !== 'production',
  // preloadedState,
});

export default store;

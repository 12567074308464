import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import Cookie from 'js-cookie';

import utils from '../utils/init';
import { setUser } from '../reducers/userReducer';

export function useCheckUser() {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  useEffect(() => {
    async function checkUser() {
      try {
        // check authentication, this will throw error if it fails
        await utils.check();
        const me = await utils.getMe();

        dispatch(setUser(me));
        setLoading(false);
      } catch (err) {
        setLoading(false);
        setError(err);
      }
    }
    if (Cookie.get('xss_token')) {
      checkUser();
    } else {
      setLoading(false);
    }
  }, [dispatch]);

  return [loading, setLoading, error, setError];
}

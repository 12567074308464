import React from 'react';
import { useSelector } from 'react-redux';
import { selectUser } from '../reducers/userReducer';

export function ProfilePage({ onSignOut }) {
  const user = useSelector(selectUser);

  return user ? (
    <p>
      Welcome {user.fullName}!{' '}
      <button type="button" onClick={onSignOut}>
        Sign out
      </button>
    </p>
  ) : (
    <p>You are not logged in.</p>
  );
}

import { createAction, createReducer } from 'redux-starter-kit';

// Read about createReducer: https://redux-starter-kit.js.org/api/createaction
// Move these to their own folder/file when the list starts getting long
export const setUser = createAction('user/set');

// Read about createReducer: https://redux-starter-kit.js.org/api/createreducer
const userReducer = createReducer(null, {
  [setUser]: (state, { payload: user }) =>
    user ? { ...(state || {}), ...user } : null,
});

// Read about createSelector: https://redux-starter-kit.js.org/api/createselector
// Move these to their own folder/file when the list starts getting long
export const selectUser = state => state.user;
/**
 * Example of a selector using createSelector (add `import { createSelector } from 'redux-starter-kit'`)
 * export const selectUserFullName = createSelector(
 *   selectUser,
 *   (user) => user.fullName,
 * );
 *
 * Example of a selector using the other selector as a dependency
 * export const selectUserFirstName = createSelector(
 *   selectUserFullName,
 *   (fullName) => user.fullName.slice(0, name.indexOf(' ')),
 * );
 */

export default userReducer;
